import React from 'react';

const Project3 = () => {
  return(
    <div className="project3-content">
      <p>PROJECT 3!</p>
    </div>
  );
};

export default Project3;
