import React from 'react';

const Project2 = () => {
  return(
    <div className="project2-content">
      <p>PROJECT 2!</p>
    </div>
  );
};

export default Project2;
